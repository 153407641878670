import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image"

import "../styles/global.css"

const ProjectSection = styled.section`
  padding-top: 30px;
  background-color: #efefef;
`

const ProjectWrapper = styled.div`
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-bottom: 1rem;

  @media only screen and (min-width: 75em) {
    margin: auto;
    max-width: 85.2rem;
  }

  @media only screen and (min-width: 62em) {
    margin: auto;
    max-width: 73.2rem;
  }
`

const ProjectDesc = styled.p`
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 10px;
`
export const query = graphql`
  query($pathSlug: String!) {
    mdx(frontmatter: { path: { eq: $pathSlug } }) {
      frontmatter {
        title
        path
        desc
        images {
          publicURL
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`
const Project = ({ data: { mdx: project } }) => {
  const { body } = project
  return (
    <div>
      <Layout>
        <ProjectSection>
          <ProjectWrapper>
            <SEO
              title={project.frontmatter.title}
              description={project.frontmatter.desc}
            />
            <Link to="/projects">
              <h3 className="margin-b-10 slim">
                <FontAwesomeIcon icon={"chevron-left"} /> ALL PROJECTS
              </h3>
            </Link>
            <Img
              fluid={project.frontmatter.featuredImage.childImageSharp.fluid}
              style={{ maxHeight: 450 }}
            />
            <h1 className="margin-t-15 margin-b-5">{project.frontmatter.title}</h1>
            <ProjectDesc>{project.frontmatter.desc}</ProjectDesc>
            <MDXRenderer images={project.frontmatter.images}>{body}</MDXRenderer>
          </ProjectWrapper>
        </ProjectSection>
      </Layout>
    </div>
  )
}
export default Project
